import { FC, memo, PropsWithChildren, useRef } from "react";
import { Flex } from "@mantine/core";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { UseVisibilityObserver } from "src/modules/products-screens/utils/useVisibilityObserver";

gsap.registerPlugin(useGSAP);
gsap.registerPlugin(ScrollTrigger);

export const AnimatedCardWrapper: FC<PropsWithChildren<{}>> = memo(({ children }) => {
    const cardElem = useRef<HTMLDivElement | null>(null);

    const isVisible = UseVisibilityObserver(cardElem);

    useGSAP(
        () => {
            if (!isVisible) return;

            gsap.fromTo(
                cardElem.current,
                { opacity: 0 },
                { opacity: 1, duration: 0.7 }
            );

            gsap.fromTo(
                cardElem.current,
                { yPercent: 100 },
                { yPercent: 0, duration: 0.2 }
            );
        },
        {
            scope: cardElem,
            dependencies: [cardElem.current, isVisible],
        }
    );

    return <Flex
        ref={cardElem}
        direction="column"
        className="card"
        bg="#EDEFEE"
        h="100%"
        style={{ borderRadius: 14, userSelect: "none" }}
    >
        {children}
    </Flex>
});
