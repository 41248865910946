import FirstImage from "../../assets/artworks/01.png";
import SecondImage from "../../assets/artworks/02.png";
import ThirdImage from "../../assets/artworks/03.png";
import FourthImage from "../../assets/artworks/04.png";

import PersonasImage from "../../assets/personas/01.png";

import TShirtImage from "../../assets/products/01.png";

const images = [FirstImage, SecondImage, ThirdImage, FourthImage];

export const artworksData = Array.from({ length: 20 }, (_, index) => ({
  id: index + 1,
  name: "Lorem Ipsum",
  category: "Dolor sit",
  src: images[index % images.length],
  gender: "M",
  age: index + 10,
  hobbies: ["Lorem Ipsum", "Dolor sit amet", "Consectetur"],
  habits: ["Lorem Ipsum", "Dolor sit amet", "Consectetur"],
  description:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque pellentesque tellus a mi luctus, in efficitur felis vestibulum. Praesent sed dui commodo, varius magna ac, varius mauris. Vivamus auctor ex vel eros faucibus, eget lobortis enim tristique. Duis bibendum fringilla orci, quis sodales risus commodo venenatis.",
  purchasingPower: "high",
  productsCreated: 32,
  sales: 187,
  lastMicroTrend: "#veganfuryattack",
  population: 1589653,
}));

export const personasData = Array.from({ length: 20 }, (_, index) => ({
  id: index + 1,
  name: "Lorem Ipsum",
  category: "Dolor sit",
  src: PersonasImage,
  gender: "M",
  age: index + 10,
  hobbies: ["Lorem Ipsum", "Dolor sit amet", "Consectetur"],
  habits: ["Lorem Ipsum", "Dolor sit amet", "Consectetur"],
  description:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque pellentesque tellus a mi luctus, in efficitur felis vestibulum. Praesent sed dui commodo, varius magna ac, varius mauris. Vivamus auctor ex vel eros faucibus, eget lobortis enim tristique. Duis bibendum fringilla orci, quis sodales risus commodo venenatis.",
  purchasingPower: "high",
  productsCreated: 32,
  sales: 187,
  lastMicroTrend: "#veganfuryattack",
  population: 1589653,
}));

export const productsData = Array.from({ length: 20 }, (_, index) => ({
  id: index + 1,
  name: "Lorem Ipsum",
  category: "Dolor sit",
  src: TShirtImage,
  gender: "M",
  age: index + 10,
  hobbies: ["Lorem Ipsum", "Dolor sit amet", "Consectetur"],
  habits: ["Lorem Ipsum", "Dolor sit amet", "Consectetur"],
  description:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque pellentesque tellus a mi luctus, in efficitur felis vestibulum. Praesent sed dui commodo, varius magna ac, varius mauris. Vivamus auctor ex vel eros faucibus, eget lobortis enim tristique. Duis bibendum fringilla orci, quis sodales risus commodo venenatis.",
  purchasingPower: "high",
  productsCreated: 32,
  sales: 187,
  lastMicroTrend: "#veganfuryattack",
  population: 1589653,
}));

export const mockedStoreInfo = {
  createdProducts: 12,
  createdPersona: 21,
  soldProducts: 213,
  createdArtwork: 156,
};

export const mockedSelectData = [
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5" },
];

export const mockedBlueprintColors = ["orange", "white", "blue", "black"];
