import { FC, memo, PropsWithChildren } from "react";
import { AnimatedCardWrapper } from "src/modules/products-screens/products-list/components/animated-card-wrapper";
import { PersonaDataType } from "src/models/persona";

interface PersonaCardProps {
    personaData: PersonaDataType;
}

export const PersonaCard: FC<PersonaCardProps & PropsWithChildren> = memo(
    ({ personaData, children }) => {
        return (
            <AnimatedCardWrapper>
                <div className="relative w-full pb-[100%] rounded-lg overflow-hidden">
                    <div className="absolute inset-0">
                        <img
                            src={personaData.image_url}
                            alt={personaData.persona_name}
                            className="h-full w-full object-cover"
                        />
                    </div>
                </div>
                {children}
            </AnimatedCardWrapper>
        );
    }
);
