import { useEffect, useMemo, useState } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useAuth0 } from '@auth0/auth0-react';
import { useDisclosure } from '@mantine/hooks';
import { IconWand } from '@tabler/icons-react';
import { Box, Button, Flex, Loader } from '@mantine/core';
import { HorizontalCardsGridRow } from 'src/components/grids/horizontal-cards-grid-row';
import { SliderControl } from 'src/modules/products-screens/products-list/components/slider-control';
import { MyPersonaForm } from 'src/modules/personas-screens/components/my-persona-form';
import { PageLayout } from 'src/components/page-layout';
import { Filter } from 'src/modules/filter';
import { deletePersonas, getPersonasData } from 'src/services/automation.service';
import { PersonaDataType } from 'src/models/persona';
import { PersonaCard } from 'src/modules/personas-screens/components/persona-card';
import { CheckboxCardElem } from 'src/modules/products-slider/components/checkbox-card';
import { PersonaInfo } from 'src/modules/personas-screens/components/persona-info';
import { PersonasFilterPills } from 'src/modules/filter/pills/personas-pills';
import { StepElem } from 'src/modules/personas-screens/components/step-elem';
import { CreationProcess } from 'src/modules/personas-screens/components/creation-process';

interface SQSMessage {
  description: string;
  result: string | null;
  task_id: string;
  title: string;
}

export const MyPersonas = () => {
  const [personasData, setPersonasData] = useState<PersonaDataType[]>([]);
  const [selectedPersonaIds, setSelectedPersonaIds] = useState<string[]>([]);
  const [selectedPersonaData, setSelectedPersonaData] = useState<PersonaDataType | null>(null);
  const [messages, setMessages] = useState<SQSMessage[]>([]);

  const [scrollPosition, setScrollPosition] = useState(0);
  const [maxScroll, setMaxScroll] = useState(0);
  const [isCreateFormOpened, setIsCreateFormOpened] = useState(false);

  const [filterOpened, { open: openFilter, close: closeFilter }] =
    useDisclosure(false);
  const [infoModalOpened, { open: openInfoModal, close: closeInfoModal }] = useDisclosure();

  const queryClient = useQueryClient();
  const { getAccessTokenSilently } = useAuth0();

  const currentMessage = messages.at(-1);

  const { data: personasQuery, isLoading, isSuccess } = useQuery({
    queryKey: ["personas"],
    queryFn: async () => {
      return getPersonasData(await getAccessTokenSilently());
    },
  });

  const personas: PersonaDataType[] = useMemo(() => {
    return isSuccess ? personasQuery?.data : [];
  }, [personasQuery?.data, isSuccess]);

  useEffect(() => {
    setPersonasData(personas);
  }, [personas]);

  const handleManualCreation = () => {
    setIsCreateFormOpened(!isCreateFormOpened);
  };

  const handlePersonaSelect = (personaId: string) => {
    if (selectedPersonaIds.includes(personaId)) {
      const filteredPersonas = selectedPersonaIds.filter(
        (id) => id !== personaId
      );
      setSelectedPersonaIds(filteredPersonas);
    } else {
      setSelectedPersonaIds([...selectedPersonaIds, personaId]);
    }
  };

  const handleDeleteSelected = async () => {
    const token = await getAccessTokenSilently();

    await deletePersonas(token, selectedPersonaIds);
    setSelectedPersonaIds([]);
    await queryClient.invalidateQueries({ queryKey: ["personas"] });
  };

  const handleSearchIconClick = (personaData: PersonaDataType) => {
    openInfoModal();
    setSelectedPersonaData(personaData);
  }

  if (isLoading) {
    return (
      <PageLayout>
        <Flex justify="center" align="center" h="100%">
          <Loader />
        </Flex>
      </PageLayout>
    );
  }

  return (
    <PageLayout>
      {selectedPersonaData && <PersonaInfo opened={infoModalOpened} personaData={selectedPersonaData} close={closeInfoModal} />}

      <div className='flex h-full w-full'>
        <div className={`flex-[0 0 ${currentMessage ? "60%" : "100%"}] ${currentMessage ? "w-[60%]" : "w-full"} flex flex-col h-full justify-between overflow-hidden p-[35px]`}>
          <div className="flex flex-row gap-5 justify-between items-center w-full">
            <Filter<PersonaDataType>
              opened={filterOpened}
              open={openFilter}
              close={closeFilter}
              type="personas"
              setFilteredData={setPersonasData}
            />
            <div className="flex flex-wrap gap-2">
              <PersonasFilterPills />
            </div>
          </div>

          <div className="flex gap-5 flex-row h-max min-h-[60%] overflow-hidden">
            {isCreateFormOpened && !currentMessage && <MyPersonaForm messages={messages} setMessages={setMessages} />}

            <div className="flex-[65%] overflow-hidden h-full">
              <HorizontalCardsGridRow
                maxScroll={maxScroll}
                setMaxScroll={setMaxScroll}
                scrollPosition={scrollPosition}
                setScrollPosition={setScrollPosition}
              >
                {currentMessage && <StepElem stepTitle={currentMessage.title || ""} />}

                {personasData?.map((personaData) => (
                  <CheckboxCardElem
                    key={personaData.persona_id}
                    id={String(personaData.persona_id)}
                    selectedIds={selectedPersonaIds} name={personaData.persona_name}
                    handleSelect={handlePersonaSelect}
                    handleSearchIconClick={() => handleSearchIconClick(personaData)}>
                    <PersonaCard
                      key={personaData.persona_id}
                      personaData={personaData}
                    />
                  </CheckboxCardElem>
                ))}
              </HorizontalCardsGridRow>
            </div>
          </div>

          <Flex justify="space-between" w="100%">
            <Button p={0} variant="transparent" onClick={handleDeleteSelected}>{selectedPersonaIds.length !== 0 && "Delete selected"}</Button>

            {!currentMessage &&
              <Flex gap={30}>
                <Button p={0} variant="transparent" onClick={handleManualCreation}>Manual creation of persona</Button>
                <Button variant="outline" c="black">
                  <Flex gap={5} align="center">
                    <IconWand height={20} />
                    Auto create Persona
                  </Flex>
                </Button>
              </Flex>}
          </Flex>

          <Flex gap={10} align="center" pr={35}>
            <SliderControl
              scrollPosition={scrollPosition}
              setScrollPosition={setScrollPosition}
              maxScroll={maxScroll}
            />

            <Flex w={100} gap={10} align="center">
              <Box w={9} h={13} bg="black" style={{ borderRadius: 3 }} />
              <SliderControl
                scrollPosition={scrollPosition}
                setScrollPosition={setScrollPosition}
                maxScroll={maxScroll}
              />
              <Box w={13} h={18} bg="black" style={{ borderRadius: 3 }} />
            </Flex>
          </Flex>
        </div>

        <div className={`${currentMessage ? "flex" : "hidden"} ${currentMessage ? "w-[40%]" : "w-[0%]"} h-full px-12 py-7 overflow-hidden transition-all duration-200 ease-linear`} style={{ borderLeft: "1px solid black" }}>
          <CreationProcess title={currentMessage?.title || ""} description={currentMessage?.description || ""} progress={messages.length} />
        </div>
      </div>
    </PageLayout>
  );
};
