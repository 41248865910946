import { FC, memo } from "react";
import { Flex, NumberInput, Select } from "@mantine/core";
import { FilterDate } from "../components/filter-date";
import { ArtworkFiltersType } from "src/modules/filter/types";
import { FilterDateType } from "src/modules/products-screens/types/filter-values";

interface ArtworksFilterProps {
    newFilters: ArtworkFiltersType;
    setNewFilters: (newFilters: ArtworkFiltersType) => void;
}

export const ArtworksFilter: FC<ArtworksFilterProps> = memo(({ newFilters, setNewFilters }) => {
    const selectedDate = {
        fromDate: newFilters.start_date ? new Date(newFilters.start_date) : null,
        toDate: newFilters.end_date ? new Date(newFilters.end_date) : null
    };

    const handleDateChange = (dateValue: FilterDateType) => {
        setNewFilters({
            ...newFilters,
            start_date: dateValue.fromDate?.toISOString().split('T')[0],
            end_date: dateValue.toDate?.toISOString().split('T')[0]
        });
    };

    return (
        <Flex direction="column" gap={15}>
            <NumberInput
                label="Persona ID"
                hideControls
                min={0}
                value={newFilters.persona_id}
                onChange={(value) =>
                    setNewFilters({
                        ...newFilters,
                        persona_id: Number(value)
                    })
                }
            />
            <Select
                label="Model Used"
                value={newFilters.model_used}
                onChange={(value) =>
                    setNewFilters({
                        ...newFilters,
                        model_used: value || undefined
                    })
                }
                data={['Flux', 'Other Models']}
            />
            <FilterDate
                selectedDate={selectedDate}
                setSelectedDate={handleDateChange}
            />
        </Flex>
    );
});