import { memo, useState } from 'react';
import { StoreInfoWrapper } from 'src/modules/store-info/components/store-info-wrapper';
import { StoreInfoContent } from 'src/modules/store-info/components/store-info-content';
import { StoreInfoForm } from 'src/modules/store-info/components/store-info-form';
import { StoreInfoData } from 'src/modules/store-info/components/store-info-data';

import { useStoreContext } from 'src/contexts';
import { StoreSelector } from 'src/modules/store-creator/components/store-selector';
import { useMutation, useQuery } from '@tanstack/react-query';
import { getCountriesList, updateStore } from 'src/services/automation.service';
import { useAuth0 } from '@auth0/auth0-react';
import { LoadingOverlay } from '@mantine/core';

export const StoreInfo = memo(() => {
  const {
    data: countryData,
    status: BotStatus,
    refetch,
  } = useQuery({
    queryKey: ['chatBotData'],
    queryFn: async () => {
      return getCountriesList();
    },
  });
  const [editMode, setEditMode] = useState(false);
  const { setStoreId } = useStoreContext();
  const { storeFullData, isStoreReading } = useStoreContext();
  const handleStoreInfoButtonClick = () => {
    if (editMode) saveForm();
    setEditMode(!editMode);
  };
  const { mutate: selectStoreUpdateMutation, isPending: isStoreUpdatePending } =
    useMutation({
      mutationFn: () => {
        return updateStorehandler();
      },
    });
  const updateStorehandler = async () => {
    if (!storeFullData?.id) return;

    try {
      const accessToken = await getAccessTokenSilently();
      const updatedInfo = {
        ...storeFullData,
        store_id: storeFullData.id,
        store_name: storeFullData.name,
        store_description: storeFullData.description,
        store_country: storeFullData.country,
      };
      const { data, error } = await updateStore(accessToken, updatedInfo);
    } catch (error) {
      console.error('Error updating store:', error);
    }
  };
  const { getAccessTokenSilently } = useAuth0();
  const saveForm = async () => {
    if (!storeFullData?.id) return;

    selectStoreUpdateMutation();
  };

  if (!setStoreId) return null;

  console.log(countryData, 'countryData');
  return (
    <StoreInfoWrapper>
      <LoadingOverlay
        visible={isStoreUpdatePending}
        zIndex={10000}
        overlayProps={{ radius: 'sm', blur: 2 }}
      />
      <StoreInfoContent
        title="Store Info"
        buttonText={editMode ? 'Save' : 'Edit'}
        onClick={handleStoreInfoButtonClick}
      >
        <div className="flex flex-row items-center gap-4">
          <div>Select a store: </div>
          <div className="w-1/3">
            <StoreSelector setStoreId={setStoreId} setEditMode={setEditMode} />
          </div>
        </div>

        <StoreInfoForm
          editMode={editMode}
          countryData={countryData && countryData.data}
        />
        <StoreInfoData />
      </StoreInfoContent>
    </StoreInfoWrapper>
  );
});
