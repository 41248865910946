import { FC, memo, PropsWithChildren } from "react";
import { Checkbox, Flex } from "@mantine/core";
import { IconSearch } from "@tabler/icons-react";

interface CheckboxCardProps {
  id: string;
  selectedIds: string[];
  name?: string;
  handleSelect: (productId: string) => void;
  handleSearchIconClick: () => void;
}

export const CheckboxCardElem: FC<CheckboxCardProps & PropsWithChildren> = memo(
  ({ id, selectedIds, name, handleSelect, handleSearchIconClick, children }) => {
    return (
      <Checkbox.Card
        w="max-content"
        checked={selectedIds.includes(id)}
        key={id}
        value={id}
        pos="relative"
        radius="md"
        onClick={() => handleSelect(id)}
        draggable={false}
        style={{ aspectRatio: 1 }}
      >
        {children}

        <Flex
          w="100%"
          px={15}
          pos="absolute"
          justify="space-between"
          bottom={15}
          style={{ zIndex: 10 }}
        >
          <Checkbox.Indicator
            size="md"
            styles={{
              icon: { cursor: "pointer" },
              indicator: { cursor: "pointer" },
            }}
          />
          <Flex
            onClick={handleSearchIconClick}
            justify="center"
            align="center"
            w={25}
            h={25}
            bg="white"
            className="hover:scale-110 transition-transform duration-200"
            style={{
              borderRadius: "50%",
              cursor: "pointer",
            }}
          >
            <IconSearch size={16} color="black" />
          </Flex>
        </Flex>

        <Flex className="opacity-0 hover:opacity-100 select-none flex justify-center items-center h-full w-full top-0 left-0 absolute transition-all duration-200">
          <span className="w-full text-center text-xs text-gray-600 absolute bottom-0 left-1/2 -translate-x-1/2 translate-y-[100%]">{name}</span>
        </Flex>
      </Checkbox.Card>
    );
  }
);
