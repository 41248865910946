import { FC, memo, useEffect, useRef, useState } from "react";
import { Box, Button, Flex, SimpleGrid, Text } from "@mantine/core";
import { SliderControl } from "src/modules/products-screens/products-list/components/slider-control";

interface ProductGeneratingProps {
  progress: number;
}

export const ProductGenerating: FC<ProductGeneratingProps> = memo(
  ({ progress }) => {
    const [scrollPosition, setScrollPosition] = useState(0);
    const [maxScroll, setMaxScroll] = useState(0);

    const container = useRef<HTMLDivElement | null>(null);

    const personaElems = Array.from({ length: 4 }).map((_) => (
      <Box bg="#D9D9D9" />
    ));

    useEffect(() => {
      if (container.current) {
        setMaxScroll(
          container.current.scrollWidth - container.current.clientWidth
        );
      }
    }, [container.current]);

    return (
      <Flex
        direction="column"
        justify="space-between"
        gap="lg"
        pos="relative"
        h="100%"
      >
        <SimpleGrid
          ref={container}
          w="100%"
          mah="60%"
          cols={2}
          style={{ aspectRatio: 1 }}
        >
          {personaElems}
        </SimpleGrid>

        <SliderControl
          maxScroll={maxScroll}
          scrollPosition={scrollPosition}
          setScrollPosition={setScrollPosition}
        />

        <Flex justify="space-between">
          <Text w="70%" fs="italic" c="grey">
            Step process description in 2 or 3 lines qedufjhsq oudhjf osbd
            fiyqds vfiyv q$ fih qpisdh pqishjd qpsijd fq^sojd q$ spdifq psid
            qpsikdi k^qosk
          </Text>

          <Text c="grey">{progress}%</Text>
        </Flex>

        <Button
          variant="outline"
          color="black"
          fz={20}
          fw={500}
          w="max-content"
          style={{ placeSelf: "end" }}
        >
          Publish selected
        </Button>

        <SliderControl
          maxScroll={maxScroll}
          scrollPosition={scrollPosition}
          setScrollPosition={setScrollPosition}
        />
      </Flex>
    );
  }
);
