import { FC, memo, ReactNode, useEffect, useRef, useState } from "react";
import { Box, Flex, Text } from "@mantine/core";
import { SliderControl } from "src/modules/products-screens/products-list/components/slider-control";

interface ProductFormLineProps {
  title: string;
  children: ReactNode[] | ReactNode;
  flex?: string;
}

export const ProductFormLine: FC<ProductFormLineProps> = memo(
  ({ title, children, flex }) => {
    const [scrollPosition, setScrollPosition] = useState(0);
    const [maxScroll, setMaxScroll] = useState(0);

    const container = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
      if (container.current) {
        setMaxScroll(
          container.current.scrollWidth - container.current.clientWidth
        );
      }
    }, [container.current]);

    return (
      <Flex ref={container} direction="column" gap={10} flex={flex}>
        <Flex align="center" justify="space-between" gap={30}>
          <Text fz={22} c="grey">
            {title}
          </Text>
          <SliderControl
            maxScroll={maxScroll}
            scrollPosition={scrollPosition}
            setScrollPosition={setScrollPosition}
          />
        </Flex>

        <Box style={{ overflow: "hidden" }} flex="1 0 auto">
          <Flex gap={10} h="100%">
            {children}
          </Flex>
        </Box>
      </Flex>
    );
  }
);
