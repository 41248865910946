import { useEffect, useState } from 'react';
import { AnimatedText } from 'src/modules/landing-screens';

export const CreationProcess = ({
    title = "",
    description = "",
    progress = 2,
    totalSteps = 5
}: {
    title: string;
    description: string;
    progress: number;
    totalSteps?: number;
}) => {
    const [displayProgress, setDisplayProgress] = useState(0);

    useEffect(() => {
        const targetProgress = ((progress - 1) / (totalSteps - 1)) * 100;
        let startTime: number;

        const animate = (currentTime: number) => {
            if (!startTime) startTime = currentTime;
            const elapsed = currentTime - startTime;
            const duration = 7000;

            if (elapsed < duration) {
                const progressValue = Math.round((elapsed / duration) * targetProgress);
                setDisplayProgress(progressValue);
                requestAnimationFrame(animate);
            } else {
                setDisplayProgress(targetProgress);
            }
        };

        requestAnimationFrame(animate);
    }, [progress, totalSteps]);

    return (
        <div className="h-full w-full flex flex-col gap-10 p-6">
            <div className="flex-grow grid grid-cols-2 gap-6 h-full w-full">
                {Array.from({ length: 4 }).map((_, index) => (
                    <div
                        key={index}
                        className="bg-gray-300 rounded-sm animate-pulse h-full w-full"
                    />
                ))}
            </div>

            <div className="flex justify-between gap-3">
                <AnimatedText textContent={description} speed={2} className='italic' fromColor='gray' toColor='gray' textSize={16} />
                <span className="text-gray-400 text-xs">
                    {displayProgress}%
                </span>
            </div>

            <div className="flex flex-col gap-4">
                <AnimatedText textContent={title} speed={2} />

                <div className="relative w-full h-3">
                    <div className="absolute top-1/2 left-0 right-0 h-0.5 bg-gray-200" />

                    <div
                        className="absolute top-1/2 left-0 h-0.5 bg-black transition-all duration-300"
                        style={{
                            width: `calc(${displayProgress}% + ${displayProgress > 0 ? '1.5px' : '0px'})`,
                        }}
                    />

                    <div className="absolute top-1/2 left-0 right-0 flex justify-between">
                        {Array.from({ length: totalSteps }).map((_, index) => {
                            const dotPosition = (index / (totalSteps - 1)) * 100;
                            const isFilled = displayProgress >= dotPosition;

                            return (
                                <div
                                    key={index}
                                    className={`w-3 h-3 rounded-full transform -translate-y-1/2 transition-colors duration-300 ${isFilled ? 'bg-black' : 'bg-gray-200'}`}
                                />
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
};
