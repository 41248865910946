import { FC, memo, ReactNode } from "react";
import { useDisclosure } from "@mantine/hooks";
import { AnimatedCardWrapper } from "src/modules/products-screens/products-list/components/animated-card-wrapper";
import { ArtworkDataType } from "src/modules/products-screens/types/artwork-data";

interface ArtworkCardProps {
  artworkData: ArtworkDataType;
  children?: ReactNode;
}

export const ArtworkCard: FC<ArtworkCardProps> = memo(
  ({ artworkData, children }) => {
    const [
      artworkInfoOpened,
      { open: openArtworkInfo, close: closeArtworkInfo },
    ] = useDisclosure();

    return (
      <AnimatedCardWrapper>
        <div className="relative w-full pb-[100%]">
          <div className="absolute inset-0">
            <img
              src={artworkData.image_url}
              alt={`Artwork ${artworkData.artwork_id}`}
              draggable={false}
              className="h-full w-full object-cover"
            />
          </div>
        </div>
        {children}
      </AnimatedCardWrapper>
    );
  }
);
