export const FILTER_CONFIGS = {
    personas: [
        { key: 'min_age', displayName: 'Min Age' },
        { key: 'max_age', displayName: 'Max Age' },
        { key: 'country', displayName: 'Country' },
        { key: 'target_audience', displayName: 'Target Audience' },
        { key: 'store_id', displayName: 'Store ID' },
        {
            key: 'start_date',
            displayName: 'Start date',
            formatValue: (value: string) => new Date(value).toLocaleDateString()
        },
        {
            key: 'end_date',
            displayName: 'End date',
            formatValue: (value: string) => new Date(value).toLocaleDateString()
        }
    ],
    artworks: [
        { key: 'persona_id', displayName: 'Persona ID' },
        { key: 'model_used', displayName: 'Model' },
        {
            key: 'start_date',
            displayName: 'Start date',
            formatValue: (value: string) => new Date(value).toLocaleDateString()
        },
        {
            key: 'end_date',
            displayName: 'End date',
            formatValue: (value: string) => new Date(value).toLocaleDateString()
        }
    ]
};
