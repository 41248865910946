import { FC, memo } from "react";
import { Box, Button, Select } from "@mantine/core";
import { BlueprintItem } from "src/modules/store-info/components/blueprint-item";
import { ProductFormLine } from "src/modules/product-creation-form/components/product-form-line";
import { BlueprintInfoElem } from "src/modules/product-creation-form/components/blueprint-info-elem";
import {
  mockedBlueprintColors,
  mockedSelectData,
} from "src/modules/mocked-data/mocked-data";

interface ProductSettingsProps {
  createProduct: () => void;
}

export const ProductSettings: FC<ProductSettingsProps> = memo(
  ({ createProduct }) => {
    const changePrice = (value: string) => {
      console.log("Price: ", value);
    };

    const changeQuantity = (value: string) => {
      console.log("Quantity: ", value);
    };

    const personaElems = Array.from({ length: 10 }).map((_) => (
      <Box h="100%" bg="#E0E0E0" style={{ aspectRatio: 1 }} />
    ));

    return (
      <>
        <Select
          placeholder="Name of the store"
          data={mockedSelectData}
          w="max-content"
        />
        <ProductFormLine title="Persona" flex="0 0 35%">
          {personaElems}
        </ProductFormLine>

        <ProductFormLine title="Blue print">
          {Array.from({ length: 10 }).map(() => (
            <BlueprintItem
              title="Name of the Blueprint"
              colors={mockedBlueprintColors}
            >
              <BlueprintInfoElem
                changePrice={changePrice}
                changeQuantity={changeQuantity}
              />
            </BlueprintItem>
          ))}
        </ProductFormLine>

        <Button
          onClick={createProduct}
          variant="outline"
          w="max-content"
          c="black"
          mx="auto"
        >
          Create Product
        </Button>
      </>
    );
  }
);
