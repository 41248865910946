import { FC, memo } from "react";
import { Slider } from "@mantine/core";

interface SliderControlProps {
  scrollPosition: number;
  setScrollPosition: (value: number) => void;
  maxScroll: number;
}

export const SliderControl: FC<SliderControlProps> = memo(
  ({ scrollPosition, setScrollPosition, maxScroll }) => {
    return (
      <Slider
        flex="1 0 auto"
        value={scrollPosition}
        onChange={(value) => setScrollPosition(value)}
        min={0}
        max={maxScroll}
        label={null}
        color="black"
        size={2}
        thumbSize={15}
        styles={{
          thumb: { borderWidth: 3, backgroundColor: "black" },
        }}
      />
    );
  }
);
