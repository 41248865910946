import { FC, memo } from "react";
import { Flex, NumberInput, TextInput } from "@mantine/core";
import { FilterDate } from "src/modules/filter/components/filter-date";
import { PersonaFiltersType } from "src/modules/filter/types";
import { FilterDateType } from "src/modules/products-screens/types/filter-values";

interface PersonasFilterProps {
    newFilters: PersonaFiltersType;
    setNewFilters: (newFilters: PersonaFiltersType) => void;
}

export const PersonasFilter: FC<PersonasFilterProps> = memo(({ newFilters, setNewFilters }) => {
    const selectedDate = {
        fromDate: newFilters.start_date ? new Date(newFilters.start_date) : null,
        toDate: newFilters.end_date ? new Date(newFilters.end_date) : null
    };

    const handleDateChange = (dateValue: FilterDateType) => {
        setNewFilters({
            ...newFilters,
            start_date: dateValue.fromDate?.toISOString().split('T')[0],
            end_date: dateValue.toDate?.toISOString().split('T')[0]
        });
    };

    return (
        <Flex direction="column" gap={15}>
            <Flex gap={15}>
                <NumberInput
                    label="Min Age"
                    value={newFilters.min_age}
                    min={0}
                    onChange={(value) =>
                        setNewFilters({
                            ...newFilters,
                            min_age: Number(value)
                        })
                    }
                />
                <NumberInput
                    label="Max Age"
                    value={newFilters.max_age}
                    min={1}
                    onChange={(value) =>
                        setNewFilters({
                            ...newFilters,
                            max_age: Number(value)
                        })
                    }
                />
            </Flex>

            <TextInput
                label="Country"
                value={newFilters.country}
                onChange={(e) =>
                    setNewFilters({
                        ...newFilters,
                        country: e.target.value
                    })
                }
            />
            <TextInput
                label="Target Audience"
                value={newFilters.target_audience}
                onChange={(e) =>
                    setNewFilters({
                        ...newFilters,
                        target_audience: e.target.value
                    })
                }
            />
            <NumberInput
                label="Store ID"
                value={newFilters.store_id}
                hideControls
                onChange={(value) =>
                    setNewFilters({
                        ...newFilters,
                        store_id: Number(value)
                    })
                }
            />
            <FilterDate
                selectedDate={selectedDate}
                setSelectedDate={handleDateChange}
            />
        </Flex>
    );
});