import { FC, memo } from "react";
import { Box, Drawer, Flex, Text, Image } from "@mantine/core";
import { PersonaDataType } from "src/models/persona";


interface PersonaInfoProps {
  opened: boolean;
  close: () => void;
  personaData: PersonaDataType;
}

export const PersonaInfo: FC<PersonaInfoProps> = memo(
  ({ opened, close, personaData }) => {
    return (
      <Drawer
        size={650}
        padding="0 30"
        opened={opened}
        onClose={close}
        returnFocus={false}
        overlayProps={{ backgroundOpacity: 0, blur: 0 }}
        styles={{
          close: {
            height: 40,
            width: 40,
          },
          content: {
            borderRight: '1px solid black',
          },
        }}
      >
        <Flex justify="space-between" gap={30} mb={20}>
          <Flex direction="column" justify="space-between" gap={20}>
            <Text fz={32} fw={600} lh={1}>
              {personaData.persona_name}
            </Text>

                        <Image w="60%" src={personaData.image_url} alt={personaData.persona_name} />

                        <Flex direction="column" fw={600}>
                            <span>ID: {personaData.persona_id}</span>
                            <span>Age: {personaData.age}</span>
                            <span>Country: {personaData.country}</span>
                        </Flex>
            <Flex direction="column">
              <Text fw={600}>
                Target Audience: {personaData.target_audience}
              </Text>
            </Flex>
          </Flex>
        </Flex>

        <Flex direction="column" justify="space-between" gap={20}>
          <Box>
            <Text fz={24} fw={600} mb={10}>
              Description
            </Text>
            <Text>{personaData.description}</Text>
          </Box>

          <Flex
            fw={600}
            columnGap={20}
            rowGap={5}
            wrap="wrap"
            justify="space-between"
          >
            <span>Persona ID: {personaData?.persona_id}</span>
            <span>Location: {personaData.country}</span>
            <span>Target Market: {personaData.target_audience}</span>
          </Flex>
        </Flex>
      </Drawer>
    );
  }
);
