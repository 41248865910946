import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import { PageLayout } from "../components/page-layout";
import {
  getProductData,
  refreshDatabase,
  syncProductsOnAmazon,
  updateAttributes,
  deleteProducts,
} from "../services/automation.service";
import ProductGrid from "../components/grids/product-grid";
import { ProductDataType } from "src/models/product";
import { AdvancedFiltersSearch } from "../components/advanced-search-filter";
import { useProducts } from "../selected-products-provider";
import {
  Text,
  Box,
  Button,
  LoadingOverlay,
  Progress,
  Flex,
} from "@mantine/core";

export const ProductsDashboard: React.FC = () => {
  const [token, setToken] = useState<string>("");
  const [products, setProducts] = useState<ProductDataType[]>([]);
  const [filteredProducts, setFilteredProducts] = useState<ProductDataType[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [syncedProducts, setSyncedProducts] = useState<ProductDataType[]>([]);
  const [refreshedProducts, setRefreshedProducts] = useState<ProductDataType[]>([]);

  const { selectedProducts } = useProducts();
  const { getAccessTokenSilently } = useAuth0();
  const getProductsData = async () => {
    try {
      const accessToken = await getAccessTokenSilently();
      setToken(accessToken);
      const { data, error } = await getProductData(accessToken);
      if (data) {
        setProducts(data);

        setFilteredProducts(data); // Initialize with all products
      }
      if (error) {
        setError("Failed to fetch products.");
      }
    } catch (error) {
      console.error(error);
      setError("An unexpected error occurred.");
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    setIsLoading(true);

    getProductsData();
  }, [getAccessTokenSilently]);

  const handleSyncProductsOnAmazon = async () => {
    setSyncedProducts([]);
    setIsLoading(true);
    try {
      await syncProductsOnAmazon(token, selectedProducts, (product) => {
        console.log(product);
        setSyncedProducts((syncedProducts) => [...syncedProducts, product]);
        setSyncedProducts([]);
      });
      if (error) {
        setError("Failed to sync products.");
      }
    } catch (error) {
      console.error(error);
      setError("An unexpected error occurred.");
    }

    setIsLoading(false);
  };

  const handleUpdateAttributes = async () => {
    setIsLoading(true);
    try {
      await updateAttributes(token, selectedProducts);
      if (error) {
        setError("Failed to update attributes.");
      }
    } catch (error) {
      console.error(error);
      setError("An unexpected error occurred.");
    }
  };

  const handleRefreshDatabase = async () => {
    setRefreshedProducts([]);
    setIsLoading(true);
    try {
      await refreshDatabase(token, (product) => {
        console.log(product);
        setRefreshedProducts((refreshedProducts) => [
          ...refreshedProducts,
          product,
        ]);
        if (refreshedProducts.length === products.length) {
          setIsLoading(false);
          setRefreshedProducts([]);
        }
      });
      if (error) {
        setError("Failed to refresh database.");
      }
    } catch (error) {
      console.error(error);
      setError("An unexpected error occurred.");
    }
  };

  return (
    <PageLayout>
      <Flex direction="column" align="center" w="100%" wrap="wrap">
        {isLoading &&
          syncedProducts.length === 0 &&
          refreshedProducts.length === 0 && <LoadingOverlay visible />}
        {error && <Text c="red">{error}</Text>}

        <Text fz={38}>Products Dashboard</Text>

        <AdvancedFiltersSearch
          products={products}
          onFilter={setFilteredProducts}
        />

        <Box w="90%">
          <ProductGrid products={filteredProducts} />
        </Box>

        {!isLoading && (
          <Flex justify="stretch" align="stretch" w="100%" h="40%" p={5} gap={5}>
            <Button
              bg="custom-dark-grey.8"
              w="25%"
              h="auto"
              fz={16}
              style={{
                textWrap: "wrap",
                boxShadow: "0px 4px 15px 4px rgba(0, 1, 0, 0.25)",
              }}
              onClick={() => {
                handleRefreshDatabase();
              }}
            >
              <Text
                fz={14}
                w="100%"
                c="custom-white.0"
                style={{ textWrap: "wrap" }}
              >
                REFRESH DATABASE
              </Text>
            </Button>
            <Button
              bg="custom-dark-grey.8"
              w="25%"
              h="auto"
              fz={16}
              style={{
                textWrap: "wrap",
                boxShadow: "0px 4px 15px 4px rgba(0, 1, 0, 0.25)",
              }}
              onClick={() => {
                handleUpdateAttributes().then(() => {
                  getProductsData();
                  setIsLoading(false);
                });
              }}
            >
              <Text
                fz={14}
                w="100%"
                c="custom-white.0"
                style={{ textWrap: "wrap" }}
              >
                GENERATE AMAZON ATTRIBUTES FOR SELECTED PRODUCTS
              </Text>
            </Button>
            <Button
              bg="custom-dark-grey.8"
              w="25%"
              h="auto"
              fz={16}
              style={{
                textWrap: "wrap",
                boxShadow: "0px 4px 15px 4px rgba(0, 1, 0, 0.25)",
              }}
              onClick={() => {
                console.log(selectedProducts);
              }}
            >
              <Text
                fz={14}
                w="100%"
                c="custom-white.0"
                style={{ textWrap: "wrap" }}
              >
                GENERATE AMAZON MOCKUPS FOR SELECTED PRODUCTS
              </Text>
            </Button>
            <Button
              bg="custom-dark-grey.8"
              w="25%"
              h="auto"
              fz={16}
              style={{
                textWrap: "wrap",
                boxShadow: "0px 4px 15px 4px rgba(0, 1, 0, 0.25)",
              }}
              onClick={() => {
                setIsLoading(true);
                // deleteProducts(token, selectedProducts).then(() => {
                //   getProductsData();
                //   setIsLoading(false);
                // });
              }}
            >
              <Text
                fz={14}
                w="100%"
                c="custom-white.0"
                style={{ textWrap: "wrap" }}
              >
                DELETE SELECTED PRODUCTS
              </Text>
            </Button>
          </Flex>
        )}
        {syncedProducts.length > 0 && isLoading && (
          <Box p={20}>
            <Progress
              size="xl"
              value={(syncedProducts.length / selectedProducts.length) * 100}
            />

            <Text fz={48}>Syncing products to Amazon...</Text>
          </Box>
        )}
        {refreshedProducts.length > 0 && isLoading && (
          <Box p={20}>
            <Progress
              size="xl"
              value={(refreshedProducts.length / products.length) * 100}
            />

            <Text fz={48}>Refreshing database...</Text>
          </Box>
        )}
      </Flex>
    </PageLayout>
  );
};
