export const headerTitles = {
  "/store": "Store",
  "/store-creator": "My Stores",
  "/store-info": "Name of the store",
  "/products-dashboard": "Products",
  "/personas-dashboard": "My Personas",
  "/my-personas": "My Personas",
  "/persona-creator": "Persona",
  "/products-creator": "My Products",
  "/products": "My Products",
  "/artworks": "My Artworks",
};
