import { useFilterContext } from "src/contexts";
import { FilterPills } from "src/modules/filter/components/filter-pill";
import { FILTER_CONFIGS } from "src/modules/filter/config";

export const PersonasFilterPills = () => {
    const { state: filters, dispatch } = useFilterContext();

    const handleRemove = (key: string) => {
        dispatch({
            type: 'SET_PERSONA_FILTER',
            payload: { [key]: "" }
        });
    };

    return (
        <FilterPills
            filters={filters.personas}
            filterConfigs={FILTER_CONFIGS.personas}
            onRemove={handleRemove}
        />
    );
};
