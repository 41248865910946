import { FC, memo, ReactNode, useEffect, useRef, useState } from "react";
import { Box, Flex, Loader } from "@mantine/core";
import { SliderControl } from "src/modules/products-screens/products-list/components/slider-control";

interface HorizontalCardsGridProps {
  children: ReactNode[];
  controller?: ReactNode;
  rows?: number;
}

export const HorizontalCardsGrid: FC<HorizontalCardsGridProps> = memo(
  ({ children, controller, rows = 1 }) => {
    const [scrollPosition, setScrollPosition] = useState(0);
    const [maxScroll, setMaxScroll] = useState(0);
    const [isDragging, setIsDragging] = useState(false);
    const [startDragX, setStartDragX] = useState(0);
    const [scrollAnimation, setScrollAnimation] = useState<number | null>(null);

    const cardsWrapper = useRef<HTMLDivElement | null>(null);
    const container = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
      if (container.current) {
        setMaxScroll(
          container.current.scrollWidth - container.current.clientWidth
        );
      }
    }, [container.current]);

    const handleMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
      setIsDragging(true);
      setStartDragX(event.clientX);
    };

    const handleMouseMove = (event: MouseEvent) => {
      if (isDragging && container.current) {
        const dx = event.clientX - startDragX;
        setScrollPosition((prev) =>
          Math.max(0, Math.min(prev - dx, maxScroll))
        );
        setStartDragX(event.clientX);
      }
    };

    const handleMouseUp = () => {
      setIsDragging(false);
    };

    const handleWheel = (event: WheelEvent) => {
      event.preventDefault();

      if (container.current) {
        const scrollSpeedMultiplier = 4;
        const newScrollPosition = Math.max(
          0,
          Math.min(
            scrollPosition + event.deltaY * scrollSpeedMultiplier,
            maxScroll
          )
        );

        smoothScroll(newScrollPosition);
      }
    };

    const smoothScroll = (targetPosition: number) => {
      if (scrollAnimation) {
        cancelAnimationFrame(scrollAnimation);
      }

      const distance = targetPosition - scrollPosition;
      const duration = 150;
      let startTime: number | null = null;

      const animateScroll = (currentTime: number) => {
        if (startTime === null) startTime = currentTime;
        const elapsed = currentTime - startTime;
        const progress = Math.min(elapsed / duration, 1);
        const easing = 0.5 * (1 - Math.cos(Math.PI * progress));

        setScrollPosition(scrollPosition + distance * easing);

        if (progress < 1) {
          setScrollAnimation(requestAnimationFrame(animateScroll));
        } else {
          setScrollAnimation(null);
        }
      };

      setScrollAnimation(requestAnimationFrame(animateScroll));
    };

    useEffect(() => {
      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
      return () => {
        document.removeEventListener("mousemove", handleMouseMove);
        document.removeEventListener("mouseup", handleMouseUp);
      };
    }, [isDragging, startDragX, maxScroll]);

    useEffect(() => {
      cardsWrapper.current?.addEventListener("wheel", handleWheel);

      return () => {
        cardsWrapper.current?.removeEventListener("wheel", handleWheel);
      };
    }, [maxScroll, scrollPosition]);

    const itemsPerRow = Math.ceil(children.length / (rows || 1));

    return (
      <Flex direction="column" justify="space-between" h="100%">
        <Flex direction="column" justify="center" flex="1 0 auto">
          <Box
            ref={cardsWrapper}
            draggable={false}
            h="100%"
            style={{ overflow: "hidden", cursor: "pointer" }}
            onMouseDown={handleMouseDown}
          >
            <Flex
              ref={container}
              direction="column"
              justify="center"
              gap={20}
              h="100%"
              style={{
                transform: `translateX(-${scrollPosition}px)`,
              }}
            >
              {Array.from({ length: rows }).map((_, index) => {
                const start = index * itemsPerRow;
                const rowItems = children.slice(start, start + itemsPerRow);

                if (rowItems.length === 0) return null;

                return (
                  <Flex
                    key={index}
                    gap={20}
                    h={rows === 1 ? "80%" : `${70 / rows}%`}
                  >
                    {rowItems}
                  </Flex>
                );
              })}
            </Flex>
          </Box>
        </Flex>

        {controller && controller}

        <Flex gap={10} align="center" pr={35}>
          <SliderControl
            scrollPosition={scrollPosition}
            setScrollPosition={setScrollPosition}
            maxScroll={maxScroll}
          />

          <Flex w={100} gap={10} align="center">
            <Box w={9} h={13} bg="black" style={{ borderRadius: 3 }} />
            <SliderControl
              scrollPosition={scrollPosition}
              setScrollPosition={setScrollPosition}
              maxScroll={maxScroll}
            />
            <Box w={13} h={18} bg="black" style={{ borderRadius: 3 }} />
          </Flex>
        </Flex>
      </Flex>
    );
  }
);
