import React, { createContext, useState, useContext, ReactNode, Dispatch, SetStateAction } from "react";
import { ProductDataType } from "./models/product";

interface ProductContextProps {
  selectedProducts: ProductDataType[];
  setSelectedProducts: Dispatch<SetStateAction<ProductDataType[]>>;
}

const ProductContext = createContext<ProductContextProps | undefined>(undefined);

interface ProductProviderProps {
  children: ReactNode;
}

export const useProducts = (): ProductContextProps => {
  const context = useContext(ProductContext);
  if (!context) {
    throw new Error("useProducts must be used within a ProductProvider");
  }
  return context;
};

export const ProductProvider: React.FC<ProductProviderProps> = ({ children }) => {
  const [selectedProducts, setSelectedProducts] = useState<ProductDataType[]>([]);
  return (
    <ProductContext.Provider value={{ selectedProducts, setSelectedProducts }}>
      {children}
    </ProductContext.Provider>
  );
};
