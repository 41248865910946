import { memo, useState } from "react";
import { Flex } from "@mantine/core";
import { ProductGenerating } from "src/modules/product-creation-form/components/product-generating";
import { ProductSettings } from "src/modules/product-creation-form/components/product-settings";

export const ProductCreationForm = memo(() => {
  const [isProductsCreating, setIsProductsCreating] = useState(false);

  const createProduct = () => {
    setIsProductsCreating(true);
  };

  return (
    <Flex direction="column" w="100%" justify="space-between">
      {isProductsCreating ? (
        <ProductGenerating progress={87} />
      ) : (
        <ProductSettings createProduct={createProduct} />
      )}
    </Flex>
  );
});
