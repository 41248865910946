import { FC, memo, useEffect, useMemo } from 'react';
import { Select } from '@mantine/core';
import { useAuth0 } from '@auth0/auth0-react';
import { useQuery } from '@tanstack/react-query';
import { getUserStores } from 'src/services/automation.service';
import { StoreBasicDataType } from 'src/modules/store-creator/types/store-data';

interface StoreSelectorProps {
  setStoreId: (storeId: number) => void;
  className?: string;
  setEditMode?: (editMode: boolean) => void;
}

export const StoreSelector: FC<StoreSelectorProps> = memo(
  ({ setStoreId, className, setEditMode }) => {
    const { getAccessTokenSilently } = useAuth0();

    const storesQuery = useQuery({
      queryKey: ['stores'],
      queryFn: async () => {
        const token = await getAccessTokenSilently();
        return getUserStores(token);
      },
    });

    const stores: StoreBasicDataType[] = useMemo(() => {
      return storesQuery.isSuccess ? storesQuery?.data?.data : [];
    }, [storesQuery]);

    const selectorData = stores?.map((store) => ({
      value: String(store.store_id),
      label: store.store_name,
    }));

    const handleSelect = (value: string | null) => {
      setStoreId(Number(value));
    };

    useEffect(() => {
      if (stores?.length === 0) return;
      setStoreId(stores[0]?.store_id);
      return () => {};
    }, [storesQuery]);

    return className ? (
      <select
        className={className}
        onChange={(e) => {
          setStoreId(Number(e.target.value));
          if (setEditMode) {
            setEditMode(false);
          }
        }}
      >
        {selectorData.map(({ value, label }) => (
          <option value={value} key={value}>
            {label}
          </option>
        ))}
      </select>
    ) : (
      <Select
        flex="1 0 auto"
        placeholder="Store"
        defaultValue={String(stores[0]?.store_id)}
        data={selectorData}
        onChange={handleSelect}
      />
    );
  }
);
