import { FC, memo } from "react";
import { Box, Drawer, Flex, Image, Text } from "@mantine/core";
import { ProductDataType } from "src/models/product";

interface ProductInfoProps {
  opened: boolean;
  close: () => void;
  productData: ProductDataType;
}

export const ProductInfo: FC<ProductInfoProps> = memo(
  ({ opened, close, productData }) => {
    return (
      <Drawer
        size={650}
        padding="md"
        opened={opened}
        onClose={close}
        returnFocus={false}
        overlayProps={{ backgroundOpacity: 0, blur: 0 }}
        styles={{
          close: {
            height: 40,
            width: 40,
          },
          content: {
            borderRight: "1px solid black",
          },
        }}
      >
        <Flex direction="column" gap="lg">
          <Text size="xl" fw={700} mb="xs">
            {productData.product_name}
          </Text>

          <Flex direction="column" gap="lg">
            <Box>
              {productData.artwork_image_url && (
                <Image
                  width="60%"
                  height={300}
                  fit="contain"
                  radius="md"
                  src={productData.artwork_image_url}
                  alt={productData.product_name}
                />
              )}
            </Box>

            {productData.product_images.length > 0 && (
              <Flex gap="xs" wrap="wrap" justify="center">
                {productData.product_images.map((image, index) => (
                  <Image
                    key={index}
                    src={image}
                    width={80}
                    height={80}
                    fit="cover"
                    radius="sm"
                    alt={`Product view ${index + 1}`}
                  />
                ))}
              </Flex>
            )}
          </Flex>

          <Box>
            <Flex fw={500} direction="column" gap="xs" mb="md">
              <span>Product ID: {productData.product_id}</span>
              <span>Category: {productData.category}</span>
              <span>Theme: {productData.theme}</span>
              <span>Collection: {productData.collection_name}</span>
            </Flex>

            <div
              dangerouslySetInnerHTML={{ __html: productData.description }}
            />
          </Box>

          {productData.blueprints.length > 0 && (
            <Box mt="lg">
              <Text size="lg" fw={600} mb="md">
                Blueprints
              </Text>
              <Flex direction="column" gap="md">
                {productData.blueprints.map((blueprint) => (
                  <Box
                    key={blueprint.id}
                    p="md"
                    style={{ border: '1px solid #eee', borderRadius: '8px' }}
                  >
                    <Text fw={500} mb="xs">{blueprint.title}</Text>
                    <div
                      dangerouslySetInnerHTML={{ __html: blueprint.description }}
                    />
                  </Box>
                ))}
              </Flex>
            </Box>
          )}

          <Flex gap="md" wrap="wrap" fw={500} justify="center">
            <span>
              Created: {new Date(productData.created_at).toLocaleDateString()}
            </span>
            <span>
              Sync Status: {productData.is_synced ? 'Synced' : 'Not Synced'}
            </span>
            <span>
              Persona ID: {productData.persona_id}
            </span>
          </Flex>
        </Flex>
      </Drawer>
    );
  }
);
