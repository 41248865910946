import { memo, useEffect, useState } from 'react';
import { Box, Button, SimpleGrid } from '@mantine/core';
import { StoreInfoWrapper } from 'src/modules/store-info/components/store-info-wrapper';
import { StoreInfoContent } from 'src/modules/store-info/components/store-info-content';
import { useStoreContext } from 'src/contexts';
import { useNavigate } from 'react-router-dom';

export const StorePersona = memo(() => {
  const { storeFullData, isStoreReading } = useStoreContext();

  const navigate = useNavigate();

  const addPersona = () => {
    navigate('/my-personas');
  };
  const [isPersonaViable, setIsPersonaViable] = useState(false);
  useEffect(() => {
    if (storeFullData?.country?.length && storeFullData?.description?.length) {
      setIsPersonaViable(true);
    } else {
      setIsPersonaViable(false);
    }

    return () => {};
  }, [storeFullData, isStoreReading]);
  const mockedPersona = new Array(8)
    .fill('')
    .map((_, index) => <Box key={index} mih={100} bg="#E0E0E0" />);
  console.log(
    storeFullData?.persona?.persona_data,
    'storeFullData?.persona?.persona_data'
  );

  console.log(isPersonaViable, 'isPersonaViable');
  return (
    <StoreInfoWrapper>
      <StoreInfoContent
        title="Main persona used"
        buttonText="Add"
        onClick={() => {
          if (isPersonaViable) {
            addPersona();
          }
        }}
      >
        <div className="h-full">
          {storeFullData?.persona?.persona_count ? (
            <SimpleGrid cols={4} spacing="xs">
              {storeFullData?.persona?.persona_data?.map((persona) => (
                <div
                  key={persona.persona_name}
                  className=" h-1/2"
                  style={{
                    backgroundImage: `url(${persona?.image_url})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    border: '2px solid grey',
                  }}
                />
              ))}
            </SimpleGrid>
          ) : (
            <div className="grid h-full place-items-center ">
              <div className="flex flex-col gap-2 items-center justify-center">
                <Button
                  style={{ width: 200 }}
                  variant="outline"
                  color="black"
                  size="sm"
                  disabled={!isPersonaViable}
                  className="get-started-btn"
                  onClick={addPersona}
                >
                  Add Persona
                </Button>
                {!isPersonaViable && (
                  <div>
                    Please Final Your Store Information first to do so{' '}
                    <a className="text-blue-500" href="/onboarding">
                      Click here
                    </a>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </StoreInfoContent>
    </StoreInfoWrapper>
  );
});
