import { useEffect, FC, useState, Dispatch, SetStateAction } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useFormik } from 'formik';
import { Card, NumberInput } from '@mantine/core';
import { useWebsocketClient } from 'src/modules/websocket-client';
import { StoreSelector } from 'src/modules/store-creator/components/store-selector';
import { createPersona } from 'src/services/automation.service';

interface SQSMessage {
  description: string;
  result: string | null;
  task_id: string;
  title: string;
}

interface MyPersonaFormProps {
  messages: SQSMessage[];
  setMessages: Dispatch<SetStateAction<SQSMessage[]>>;
}

export const MyPersonaForm: FC<MyPersonaFormProps> = ({ messages, setMessages }) => {
  const { getAccessTokenSilently } = useAuth0();
  const { isConnected, websocketClient } = useWebsocketClient();
  const [isLoading, setIsLoading] = useState(false);

  const handleWebSocketMessage = (event: MessageEvent<string>) => {
    try {
      const parsedData = JSON.parse(event.data) as SQSMessage;
      console.log('New message received:', parsedData);

      setMessages((prevMessages: SQSMessage[]) => {
        setIsLoading(false);

        return [...prevMessages, parsedData];
      });
    } catch (error) {
      console.error('Error handling websocket message:', error);
    }
  };

  useEffect(() => {
    if (!isConnected || !websocketClient?.webSocketInstance) return;

    websocketClient.webSocketInstance.addEventListener('message', handleWebSocketMessage);

    websocketClient.webSocketInstance.send(JSON.stringify({
      action: "RecieveMessage"
    }));

    return () => {
      websocketClient.webSocketInstance.removeEventListener('message', handleWebSocketMessage);
    };
  }, [isConnected, websocketClient?.webSocketInstance, handleWebSocketMessage]);

  useEffect(() => {
    if (!isConnected && messages.length > 0 && !isLoading) {
      setMessages([]);
    }
  }, [isConnected, messages.length, isLoading, setMessages]);

  const formik = useFormik({
    initialValues: {
      store_id: null as number | null,
      quantity: "1",
      user_guidance: "",
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      if (!values.store_id || !isConnected) return;

      try {
        setIsLoading(true);
        setMessages([]);

        const accessToken = await getAccessTokenSilently();
        await createPersona(
          accessToken,
          values.store_id,
          values.quantity,
          values.user_guidance
        );
      } catch (error) {
        console.error('Error creating persona:', error);
        setIsLoading(false);
      }
    },
  });

  return (
    <Card
      shadow="sm"
      padding="lg"
      radius="md"
      withBorder
      style={{
        borderColor: 'black',
        borderWidth: '1px',
        backgroundColor: '#fffbeb',
      }}
      className="shadow-md flex-[35%] h-max sm:w-[80%] md:w-[50%] lg:w-[35%] min-w-max m-auto"
    >
      <div className="flex flex-col justify-center h-full">
        <div className="p-4 md:p-6 w-full h-full border-1 border-black rounded-lg">
          <form onSubmit={formik.handleSubmit}>
            <div className="mb-4 flex flex-col md:flex-row justify-between items-center gap-2">
              <StoreSelector
                setStoreId={(id: number) => formik.setFieldValue("store_id", id)}
                className='w-max min-w-28 md:w-1/3 h-10 border-1 border-black rounded bg-[#fffbeb] text-black mb-4 md:mb-0'
              />

              <div className="flex items-center mb-4 md:mb-0">
                <span className="mr-2 text-black text-nowrap">Creation of</span>
                <NumberInput
                  size="sm"
                  radius="xs"
                  clampBehavior="strict"
                  min={0}
                  className="w-24 h-12 p-2 border-1 border-black rounded bg-[#fffbeb] text-black"
                  value={formik.values.quantity}
                  onChange={(value) => formik.setFieldValue("quantity", Number(value))}
                  disabled={isLoading}
                />
                <span className="ml-2 text-black">Personas</span>
              </div>
            </div>

            <div className="mb-4 h-[20vw] md:h-[15vw] lg:h-[12vw]">
              <textarea
                name="user_guidance"
                value={formik.values.user_guidance}
                onChange={formik.handleChange}
                placeholder="Describe a bit your audience..."
                className="w-full h-full p-2 border-1 border-black rounded bg-[#fffbeb] text-black resize-none"
                disabled={isLoading}
              />
            </div>

            <div className="w-full flex justify-center mt-6">
              <button
                type="submit"
                className={`2xl:py-3 xl:py-2 px-8 md:px-12 font-semibold text-lg md:text-xl cursor-pointer text-black rounded transition-colors ${isLoading || !isConnected
                  ? 'bg-gray-200 cursor-not-allowed'
                  : 'bg-[#fffbeb] hover:bg-gray-100'
                  }`}
                disabled={isLoading || !isConnected}
              >
                {isLoading ? 'Processing...' : 'Launch creation'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </Card>
  );
};
