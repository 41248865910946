import { RouteProps } from 'react-router-dom';
import { AuthenticationGuard } from 'src/components/authentication-guard';
import { CallbackPage } from 'src/pages/callback-page';
import { NotFoundPage } from 'src/pages/not-found-page';
import { PersonaCreatorPage } from 'src/pages/persona-creator-page';
import { ProductsCreatorPage } from 'src/pages/products-creator-page';
import { ProductsDashboard } from 'src/pages/products-dashboard-page';
import { LandingPage } from 'src/pages/landing';
import { ArtworksPage } from 'src/pages/artworks-page';
import { StorePage } from 'src/pages/store-page';
import { StoreCreatorPage } from 'src/pages/store-creator-page';
import { StoreInfoPage } from 'src/pages/store-info-page';
import { ProductsPage } from 'src/pages/products-page';
import { MyPersonas } from 'src/pages/my-personas';

import Onboarding from 'src/modules/onboarding/Onboarding';
import { LoginPage } from 'src/pages/login-page';

export const appRoutesDescription: Array<RouteProps> = [
  {
    path: '/',
    element: <LandingPage />,
  },
  {
    path: '/login',
    element: <LoginPage />,
  },
  {
    path: '/onboarding',
    element: <AuthenticationGuard component={Onboarding} />,
  },
  {
    path: '/store',
    element: <AuthenticationGuard component={StorePage} />,
  },
  {
    path: '/store-creator',
    element: <AuthenticationGuard component={StoreCreatorPage} />,
  },
  {
    path: '/store-info',
    element: <AuthenticationGuard component={StoreInfoPage} />,
  },
  {
    path: '/products-dashboard',
    element: <AuthenticationGuard component={ProductsDashboard} />,
  },
  {
    path: '/my-personas',
    element: <AuthenticationGuard component={MyPersonas} />,
  },
  {
    path: '/persona-creator',
    element: <AuthenticationGuard component={PersonaCreatorPage} />,
  },
  {
    path: '/products-creator',
    element: <AuthenticationGuard component={ProductsCreatorPage} />,
  },
  {
    path: '/products',
    element: <AuthenticationGuard component={ProductsPage} />,
  },
  {
    path: '/artworks',
    element: <AuthenticationGuard component={ArtworksPage} />,
  },
  {
    path: '/callback',
    element: <CallbackPage />,
  },
  {
    path: '*',
    element: <NotFoundPage />,
  },
];
