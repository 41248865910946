import { FC, memo, PropsWithChildren, useEffect, useRef, useState } from "react";

interface HorizontalCardsGridProps {
  maxScroll: number;
  scrollPosition: number;
  setMaxScroll: (maxScroll: number) => void;
  setScrollPosition: React.Dispatch<React.SetStateAction<number>>;
}

export const HorizontalCardsGridRow: FC<HorizontalCardsGridProps & PropsWithChildren> = memo(
  ({ scrollPosition, maxScroll, setMaxScroll, setScrollPosition, children }) => {
    const [isDragging, setIsDragging] = useState(false);
    const [startDragX, setStartDragX] = useState(0);
    const [scrollAnimation, setScrollAnimation] = useState<number | null>(null);

    const cardsWrapper = useRef<HTMLDivElement | null>(null);
    const container = useRef<HTMLDivElement | null>(null);

    // Calculate max scroll once on mount
    useEffect(() => {
      setTimeout(() => {
        if (container.current) {
          setMaxScroll(
            container.current.scrollWidth - container.current.clientWidth
          );
        }
      }, 100);
    }, [container.current]);

    // Handle drag functionality
    const handleMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
      setIsDragging(true);
      setStartDragX(event.clientX);
    };

    const handleMouseMove = (event: MouseEvent) => {
      if (isDragging && container.current) {
        const dx = event.clientX - startDragX;
        setScrollPosition((prev) =>
          Math.max(0, Math.min(prev - dx, maxScroll))
        );
        setStartDragX(event.clientX);
      }
    };

    const handleMouseUp = () => {
      setIsDragging(false);
    };

    // Handle wheel scroll functionality
    const handleWheel = (event: WheelEvent) => {
      event.preventDefault();
      if (container.current) {
        const scrollSpeedMultiplier = 4;
        const newScrollPosition = Math.max(
          0,
          Math.min(
            scrollPosition + event.deltaY * scrollSpeedMultiplier,
            maxScroll
          )
        );
        smoothScroll(newScrollPosition);
      }
    };

    // Smooth scroll animation
    const smoothScroll = (targetPosition: number) => {
      if (scrollAnimation) {
        cancelAnimationFrame(scrollAnimation);
      }
      const distance = targetPosition - scrollPosition;
      const duration = 150;
      let startTime: number | null = null;

      const animateScroll = (currentTime: number) => {
        if (startTime === null) startTime = currentTime;
        const elapsed = currentTime - startTime;
        const progress = Math.min(elapsed / duration, 1);
        const easing = 0.5 * (1 - Math.cos(Math.PI * progress));

        setScrollPosition(scrollPosition + distance * easing);

        if (progress < 1) {
          setScrollAnimation(requestAnimationFrame(animateScroll));
        } else {
          setScrollAnimation(null);
        }
      };

      setScrollAnimation(requestAnimationFrame(animateScroll));
    };

    useEffect(() => {
      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
      return () => {
        document.removeEventListener("mousemove", handleMouseMove);
        document.removeEventListener("mouseup", handleMouseUp);
      };
    }, [isDragging, startDragX, maxScroll]);

    useEffect(() => {
      if (cardsWrapper.current) {
        cardsWrapper.current.addEventListener("wheel", handleWheel);
      }
      return () => {
        if (cardsWrapper.current) {
          cardsWrapper.current.removeEventListener("wheel", handleWheel);
        }
      };
    }, [maxScroll, scrollPosition]);

    return (
      <div className="flex flex-col justify-center flex-grow h-full">
        <div
          ref={cardsWrapper}
          className="h-full overflow-hidden cursor-pointer"
          onMouseDown={handleMouseDown}
        >
          <div
            ref={container}
            className="flex flex-col justify-center gap-5 h-full"
            style={{ transform: `translateX(-${scrollPosition}px)` }}
          >
            <div className="flex gap-5 h-[50%]">
              {children}
            </div>
          </div>
        </div>
      </div>
    );
  }
);
