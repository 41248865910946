import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect } from 'react';
import { PageLoader } from './components/page-loader';
import { ProductProvider } from './selected-products-provider';
import { PrintfulProvider } from './printful-context-provider';
import { NicheProvider } from './selected-niche-provider';
import { StepperProvider } from './stepper-provider';
import { useDisclosure } from '@mantine/hooks';
import { AppShell } from '@mantine/core';
import { PersonaProvider } from './selected-personas-provider';
import { appRoutes } from './router';
import {
  getCurrentStores,
  saveUserDataInSession,
} from './services/automation.service';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Header } from './modules/header';
import { Menu } from './modules/products-screens/components/menu';
import { FilterProvider, StoreProvider } from './contexts';
import './index.css';
import { useMutation } from '@tanstack/react-query';

export const App: React.FC = () => {
  const navigate = useNavigate();
  const { isLoading, isAuthenticated, user, getAccessTokenSilently } =
    useAuth0();
  const { mutate: updateApiKey, isPending } = useMutation({
    mutationFn: () => {
      return updateStoreAPIKeyhandler();
    },
  });
  const location = useLocation();
  const updateStoreAPIKeyhandler = async () => {
    try {
      const token = await getAccessTokenSilently();
      const data = await saveUserDataInSession(token, user);
      console.log(data, 'datadatadatadata -- in after mutation');
      if (data && 'onboarding_step' in data) {
        if (data.onboarding_step === 'user_logged') {
          navigate('/onboarding');
        } else if (data.onboarding_step === 'supplier_added') {
          navigate('/onboarding');
        } else if (
          data.onboarding_step === 'completed' &&
          location.pathname === '/'
        ) {
          navigate('/store-info');
        }
      }
    } catch (error) {
      console.error('Error updating store:', error);
    }
  };
  const [isMenuOpened, { toggle: toggleMenu }] = useDisclosure(false);

  console.log(location.pathname, 'pathname');
  console.log(
    location,
    isLoading,
    isAuthenticated,
    user,
    getAccessTokenSilently,
    'location'
  );

  useEffect(() => {
    if (isAuthenticated && user) {
      const saveUserData = async () => {
        const accessToken = await getAccessTokenSilently();
        updateApiKey();
      };

      const updateUserStores = async () => {
        const accessToken = await getAccessTokenSilently();
        await getCurrentStores(accessToken);
      };

      saveUserData();
      updateUserStores();
    }
  }, [isAuthenticated, user, getAccessTokenSilently]);

  if (isLoading || isPending) {
    return (
      <div>
        <PageLoader />
      </div>
    );
  }

  return (
    // <ModalManagerProvider>
    <PersonaProvider>
      <PrintfulProvider>
        <StoreProvider>
          <FilterProvider>
            <StepperProvider>
              <NicheProvider>
                <ProductProvider>
                  <AppShell
                    navbar={{
                      width: 180,
                      breakpoint: 0,
                      collapsed: {
                        mobile: !isMenuOpened,
                        desktop: !isMenuOpened,
                      },
                    }}
                    pl={
                      isMenuOpened ||
                      location.pathname == '/' ||
                      location.pathname == '/login'
                        ? 0
                        : 30
                    }
                    style={{ transition: 'padding 0.2s linear' }}
                  >
                    {location.pathname !== '/' &&
                      location.pathname !== '/login' && (
                        <>
                          <AppShell.Header
                            styles={{
                              header: {
                                zIndex: 50,
                                width: '100vw',
                              },
                            }}
                          >
                            <Header
                              withAnimation={false}
                              paddingLeft={isMenuOpened ? 180 : 0}
                            />
                          </AppShell.Header>
                          <AppShell.Navbar p="md">
                            <Menu opened={isMenuOpened} toggle={toggleMenu} />
                          </AppShell.Navbar>
                        </>
                      )}
                    <AppShell.Main pt="8vh" mih="100vh">
                      {appRoutes}
                    </AppShell.Main>
                  </AppShell>
                </ProductProvider>
              </NicheProvider>
            </StepperProvider>
          </FilterProvider>
        </StoreProvider>
      </PrintfulProvider>
    </PersonaProvider>
    // </ModalManagerProvider>
  );
};
