import { FC } from "react";
import { Image } from "@mantine/core";
import logoImg from "src/assets/icons/logo-black.png";

interface StepElemProps {
    stepTitle: string;
}

export const StepElem: FC<StepElemProps> = ({ stepTitle }) => {
    return (
        <div draggable={false} className="h-full border aspect-square border-black border-solid rounded-xl text-gray-400 flex flex-col items-center justify-center p-4 gap-2 text-center">
            <Image src={logoImg} alt="logo" w={100} />
            <span className="text-[16px] leading-none">{stepTitle}</span>
            <span className="text-sm">{`Creation of personas`}</span>
        </div>
    );
};
