import { memo, useEffect, useMemo, useState } from "react";
import { Box, Button, Flex, Loader } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useAuth0 } from "@auth0/auth0-react";
import { Filter } from "src/modules/filter";
import { PageLayout } from "src/components/page-layout";
import { deleteArtwork, getArtworks } from "src/services/automation.service";
import { ArtworkCard } from "src/modules/products-screens/products-list/components/artwork-card";
import { CheckboxCardElem } from "src/modules/products-slider/components/checkbox-card";
import { HorizontalCardsGridRow } from "src/components/grids/horizontal-cards-grid-row";
import { SliderControl } from "src/modules/products-screens/products-list/components/slider-control";
import { ArtworksFilterPills } from "src/modules/filter/pills";
import { ArtworkDataType } from "src/modules/products-screens/types/artwork-data";
import { ArtworkInfo } from "src/modules/artworks-screens/components/artwork-info";

export const ArtworksPage = memo(() => {
  const [selectedArtworkIds, setSelectedArtworkIds] = useState<string[]>([]);
  const [artworksData, setArtworksData] = useState<ArtworkDataType[]>([]);
  const [selectedArtworkData, setSelectedArtworkData] = useState<ArtworkDataType | null>(null);

  const [scrollPosition, setScrollPosition] = useState(0);
  const [maxScroll, setMaxScroll] = useState(0);

  const [artworkInfoOpened, { open: openArtworktInfo, close: closeArtworsInfo }] = useDisclosure();
  const [filterOpened, { open: openFilter, close: closeFilter }] = useDisclosure(false);

  const queryClient = useQueryClient();
  const { getAccessTokenSilently } = useAuth0();

  const { data: artworksQuery, isSuccess, isLoading } = useQuery({
    queryKey: ["artworks"],
    queryFn: async () => {
      return getArtworks(await getAccessTokenSilently());
    },
  });

  const artworks = useMemo(() => {
    return isSuccess ? artworksQuery?.data : [];
  }, [artworksQuery?.data, isSuccess]);

  useEffect(() => {
    setArtworksData(artworks);
  }, [artworks]);

  const handleArtworkSelect = (artworkId: string) => {
    if (selectedArtworkIds.includes(artworkId)) {
      const filteredArtworks = selectedArtworkIds.filter((id) => id !== artworkId);
      setSelectedArtworkIds(filteredArtworks);
    } else {
      setSelectedArtworkIds([...selectedArtworkIds, artworkId]);
    }
  };

  const handleDeleteSelected = async () => {
    const token = await getAccessTokenSilently();

    await deleteArtwork(token, selectedArtworkIds);
    setSelectedArtworkIds([]);
    await queryClient.invalidateQueries({ queryKey: ["artworks"] });
  };

  const handleSearchIconClick = (artworkData: ArtworkDataType) => {
    openArtworktInfo();
    setSelectedArtworkData(artworkData);
  };

  if (isLoading) {
    return (
      <PageLayout>
        <Flex justify="center" align="center" h="100%">
          <Loader />
        </Flex>
      </PageLayout>
    );
  }

  return (
    <PageLayout>
      {selectedArtworkData && <ArtworkInfo opened={artworkInfoOpened} artworkData={selectedArtworkData} close={closeArtworsInfo} />}

      <div className="flex flex-col h-full w-full justify-between overflow-hidden p-[35px]">
        <div className="flex flex-row gap-5 justify-between items-center w-full">
          <Filter<ArtworkDataType>
            opened={filterOpened}
            open={openFilter}
            close={closeFilter}
            type="artworks"
            setFilteredData={setArtworksData}
          />
          <div className="flex flex-wrap gap-2">
            <ArtworksFilterPills />
          </div>
        </div>

        <div className="flex gap-5 flex-row h-max min-h-[60%] overflow-hidden">
          <div className="flex-[65%] overflow-hidden h-full">
            <HorizontalCardsGridRow
              maxScroll={maxScroll}
              setMaxScroll={setMaxScroll}
              scrollPosition={scrollPosition}
              setScrollPosition={setScrollPosition}
            >
              {artworksData?.map((artworkData) => (
                <CheckboxCardElem
                  key={artworkData.artwork_id}
                  id={String(artworkData.artwork_id)}
                  selectedIds={selectedArtworkIds}
                  handleSearchIconClick={() => handleSearchIconClick(artworkData)}
                  handleSelect={handleArtworkSelect}
                >
                  <ArtworkCard
                    key={artworkData.artwork_id}
                    artworkData={artworkData}
                  />
                </CheckboxCardElem>
              ))}
            </HorizontalCardsGridRow>
          </div>
        </div>

        <Flex justify="space-between" w="100%">
          <Button p={0} variant="transparent" onClick={handleDeleteSelected}>
            {selectedArtworkIds.length !== 0 && "Delete selected"}
          </Button>
        </Flex>

        <Flex gap={10} align="center" pr={35}>
          <SliderControl
            scrollPosition={scrollPosition}
            setScrollPosition={setScrollPosition}
            maxScroll={maxScroll}
          />

          <Flex w={100} gap={10} align="center">
            <Box w={9} h={13} bg="black" style={{ borderRadius: 3 }} />
            <SliderControl
              scrollPosition={scrollPosition}
              setScrollPosition={setScrollPosition}
              maxScroll={maxScroll}
            />
            <Box w={13} h={18} bg="black" style={{ borderRadius: 3 }} />
          </Flex>
        </Flex>
      </div>
    </PageLayout>
  );
});
