import { memo, useEffect, useMemo, useState } from "react";
import { Box, Button, Flex } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconWand } from "@tabler/icons-react";
import { HorizontalCardsGrid } from "src/components/grids/horizontal-cards-grid";
import { Filter } from "src/modules/filter";
import { ProductCreationForm } from "src/modules/product-creation-form";
import { PageLayout } from "src/components/page-layout";
import { CheckboxCardElem } from "src/modules/products-slider/components/checkbox-card";
import { ProductCard } from "src/modules/products-screens/products-list/components/product-card";
import { ProductDataType } from "src/models/product";
import { ProductInfo } from "src/modules/products-screens/products-list/components/product-info";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { deleteProducts, getProducts } from "src/services/automation.service";
import { useAuth0 } from "@auth0/auth0-react";

export const ProductsPage = memo(() => {
  const [isCreateFormOpened, setIsCreateFormOpened] = useState(false);
  const [selectedProductIds, setSelectedProductIds] = useState<string[]>([]);
  const [productsData, setProductsData] = useState<ProductDataType[]>([]);
  const [selectedProductData, setSelectedProductData] = useState<ProductDataType | null>(null);

  const [productInfoOpened, { open: openProductInfo, close: closeProductInfo }] = useDisclosure();
  const [filterOpened, { open: openFilter, close: closeFilter }] =
    useDisclosure(false);

  const queryClient = useQueryClient();
  const { getAccessTokenSilently } = useAuth0();

  const { data: productsQuery, isSuccess, isLoading } = useQuery({
    queryKey: ["products"],
    queryFn: async () => {
      return getProducts(await getAccessTokenSilently());
    },
  });

  const products = useMemo(() => {
    return isSuccess ? productsQuery?.data : [];
  }, [productsQuery?.data, isSuccess]);

  useEffect(() => {
    setProductsData(products);
  }, [products]);

  const handleProductSelect = (productId: string) => {
    if (selectedProductIds.includes(productId)) {
      const filteredProducts = selectedProductIds.filter((id) => id !== productId);
      setSelectedProductIds(filteredProducts);
    } else {
      setSelectedProductIds([...selectedProductIds, productId]);
    }
  };

  const handleDeleteSelected = async () => {
    const token = await getAccessTokenSilently();

    await deleteProducts(token, selectedProductIds);
    setSelectedProductIds([]);
    await queryClient.invalidateQueries({ queryKey: ["products"] });
  };

  const handleManualCreation = () => {
    setIsCreateFormOpened(!isCreateFormOpened);
  };

  const handleSearchIconClick = (productData: ProductDataType) => {
    openProductInfo();
    setSelectedProductData(productData);
  };

  const controllerElem = <Flex justify="space-between" mb={20}>
    <Button p={0} variant="transparent" onClick={handleDeleteSelected}>{selectedProductIds.length !== 0 && "Delete selected"}</Button>

    <Flex gap={30}>
      <Button p={0} variant="transparent" onClick={handleManualCreation}>Manual creation of products</Button>
      <Button variant="outline" c="black">
        <Flex gap={5} align="center">
          <IconWand height={20} />
          Auto create products
        </Flex>
      </Button>
    </Flex>
  </Flex>;

  return (
    <PageLayout>
      {selectedProductData && <ProductInfo opened={productInfoOpened} close={closeProductInfo} productData={selectedProductData} />}

      <Flex h="100%">
        <Flex
          direction="column"
          pt={20}
          pb={40}
          px={50}
          flex={isCreateFormOpened ? "0 0 60%" : "0 0 100%"}
          w="60%"
          style={{ borderRight: "1px solid black" }}
        >
          <Filter<ProductDataType>
            opened={filterOpened}
            open={openFilter}
            close={closeFilter}
            type="personas"
            setFilteredData={setProductsData} />

          <Box flex="1 0 auto">
            <HorizontalCardsGrid rows={2} controller={controllerElem}>
              {productsData?.map((productData) => (
                <CheckboxCardElem
                  id={String(productData.id)}
                  selectedIds={selectedProductIds}
                  name={productData.product_name}
                  handleSelect={handleProductSelect}
                  handleSearchIconClick={() => handleSearchIconClick(productData)}>
                  <ProductCard productData={productData} key={productData.id} />
                </CheckboxCardElem>
              ))}
            </HorizontalCardsGrid>
          </Box>
        </Flex>

        <Flex display={isCreateFormOpened ? "flex" : "none"} px={50} py={30} style={{ overflowX: "hidden", transition: "all 0.3s linear" }}>
          <ProductCreationForm />
        </Flex>
      </Flex>
    </PageLayout>
  );
});
