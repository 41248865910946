import {
  FC,
  useContext,
  PropsWithChildren,
  memo,
  createContext,
  useMemo,
  useState,
  useEffect,
} from 'react';
import { useQuery } from '@tanstack/react-query';
import { useAuth0 } from '@auth0/auth0-react';
import { readStore } from 'src/services/automation.service';
import { StoreContextType } from 'src/modules/store-info/types/context-type';
import { StoreFullDataType } from 'src/modules/store-creator/types/store-data';

const initialValue: StoreContextType = {
  storeId: null,
  storeFullData: null,
  isStoreReading: false,
};

const StoreContext = createContext<StoreContextType>(initialValue);

export const StoreProvider: FC<PropsWithChildren<{}>> = memo(({ children }) => {
  const [storeId, setStoreId] = useState<number | null>(null);
  const [storeFullData, setStoreFullData] = useState<StoreFullDataType | null>(
    null
  );
  const { getAccessTokenSilently } = useAuth0();

  const {
    data: readStoreQuery,
    isLoading: isStoreReading,
    isSuccess,
  } = useQuery({
    queryKey: ['read-store', storeId],
    queryFn: async () => {
      const token = await getAccessTokenSilently();
      return storeId ? readStore(token, storeId) : null;
    },
    enabled: !!storeId,
  });

  useEffect(() => {
    if (isSuccess && readStoreQuery?.data) {
      setStoreFullData &&
        setStoreFullData(() => readStoreQuery.data as StoreFullDataType);
    }
  }, [isSuccess, readStoreQuery]);

  const contextValue = useMemo(
    () => ({
      storeId,
      setStoreId,
      storeFullData,
      setStoreFullData,
      isStoreReading,
    }),
    [storeFullData, isStoreReading, storeId]
  );

  return (
    <StoreContext.Provider value={contextValue}>
      {children}
    </StoreContext.Provider>
  );
});

export const useStoreContext = () => useContext(StoreContext);
