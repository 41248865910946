import { FC, memo, PropsWithChildren, useEffect, useRef, useState } from "react";
import { Box, Image } from "@mantine/core";
import { AnimatedCardWrapper } from "src/modules/products-screens/products-list/components/animated-card-wrapper";
import { ProductDataType } from "src/models/product";

interface ProductCardProps {
  productData: ProductDataType;
  withDescription?: boolean;
}

export const ProductCard: FC<ProductCardProps & PropsWithChildren> = memo(
  ({ productData, children }) => {
    const [productSize, setProductSize] = useState(0);

    const productImage = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
      if (productImage.current) {
        setProductSize(productImage.current.offsetHeight);
      }
    }, [productImage.current]);

    return (
      <AnimatedCardWrapper>
        <Box flex="1 0 auto" ref={productImage}>
          <Image
            w={productSize}
            h="100%"
            draggable={false}
            src={productData.product_images[0]}
            alt={productData.product_name}
          />
        </Box>

        {children}
      </AnimatedCardWrapper>
    );
  }
);
