import { PersonaFiltersType, ArtworkFiltersType } from "src/modules/filter/types";

export const initialPersonaFilters: PersonaFiltersType = {
    min_age: 0,
    max_age: 0,
    country: "",
    target_audience: "",
    store_id: 0,
    start_date: "",
    end_date: "",
};

export const initialArtworkFilters: ArtworkFiltersType = {
    persona_id: 0,
    model_used: "",
    start_date: "",
    end_date: "",
};
