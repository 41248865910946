import { FC } from 'react';
import { FilterConfig } from 'src/modules/filter/types';

export const FilterPills: FC<{
    filters: Record<string, any>;
    filterConfigs: FilterConfig[];
    onRemove: (key: string) => void;
}> = ({ filters, filterConfigs, onRemove }) => {
    return (
        <>
            {filterConfigs.map(({ key, displayName, formatValue }) => {
                const value = filters[key];
                if (!value) return null;

                const displayValue = formatValue ? formatValue(value) : value;

                return (
                    <div
                        className="px-2 py-1 items-center flex flex-row w-fit gap-3 text-sm font-normal rounded text-black"
                        style={{ border: '1px solid black' }}
                    >
                        <span className="text-nowrap">
                            {displayName}: {displayValue}
                        </span>
                        <span onClick={() => onRemove(key)} className="cursor-pointer">
                            X
                        </span>
                    </div>
                );
            })}
        </>
    );
};
