import { FC, memo } from "react";
import { Flex, TextInput } from "@mantine/core";

interface BlueprintInfoElemProps {
  changePrice: (value: string) => void;
  changeQuantity: (value: string) => void;
}

export const BlueprintInfoElem: FC<BlueprintInfoElemProps> = memo(
  ({ changePrice, changeQuantity }) => {
    return (
      <Flex direction="column">
        <TextInput
          label="Price"
          placeholder="25"
          onChange={(e) => changePrice(e.target.value)}
          styles={{
            root: {
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "10px",
            },
            label: { whiteSpace: "nowrap", fontSize: "12px", color: "grey" },
          }}
        />

        <Flex justify="space-between" fz={12} fw={500} c="grey">
          <span>Benefits per sell</span>
          <span>8.85$</span>
        </Flex>

        <TextInput
          label="Quantity of artworks"
          placeholder="2"
          fz={12}
          onChange={(e) => changeQuantity(e.target.value)}
          styles={{
            root: {
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "10px",
            },
            label: { whiteSpace: "nowrap", fontSize: "12px", color: "grey" },
            input: { padding: 5, minWidth: 30 },
          }}
        />
      </Flex>
    );
  }
);
