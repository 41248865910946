import { FC, memo, ReactElement } from 'react';
import { Flex } from '@mantine/core';

interface StoreInfoWrapperProps {
  children: ReactElement | ReactElement[];
  style?: React.CSSProperties;
}

export const StoreInfoWrapper: FC<StoreInfoWrapperProps> = memo(
  ({ children, style }) => {
    return (
      <Flex
        px={{ base: 20, xl: 40 }}
        py={{ base: 10, md: 20 }}
        style={{
          borderRight: '1px solid black',
          borderBottom: '1px solid black',
          '@media (min-width: 768px)': {
            overflowY: 'auto',
          },
          ...style,
        }}
      >
        {children}
      </Flex>
    );
  }
);
