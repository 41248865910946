import { createContext, useContext, useReducer, ReactNode } from 'react';
import { PersonaFiltersType, ArtworkFiltersType, FilterType } from 'src/modules/filter/types';

interface FilterState {
  personas: PersonaFiltersType;
  artworks: ArtworkFiltersType;
  activeFilterType: FilterType | null;
}

const initialFilterState: FilterState = {
  personas: {},
  artworks: {},
  activeFilterType: null
};

type FilterAction =
  | { type: "SET_PERSONA_FILTER"; payload: Partial<PersonaFiltersType> }
  | { type: "SET_ARTWORK_FILTER"; payload: Partial<ArtworkFiltersType> }
  | { type: "SET_FILTER_TYPE"; payload: FilterType }
  | { type: "RESET_FILTERS" };

function filterReducer(state: FilterState, action: FilterAction): FilterState {
  switch (action.type) {
    case "SET_PERSONA_FILTER":
      return {
        ...state,
        personas: { ...state.personas, ...action.payload }
      };
    case "SET_ARTWORK_FILTER":
      return {
        ...state,
        artworks: { ...state.artworks, ...action.payload }
      };
    case "SET_FILTER_TYPE":
      return {
        ...state,
        activeFilterType: action.payload
      };
    case "RESET_FILTERS":
      return {
        ...initialFilterState,
        activeFilterType: state.activeFilterType
      };
    default:
      return state;
  }
}

const FilterContext = createContext<{
  state: FilterState;
  dispatch: React.Dispatch<FilterAction>;
}>({
  state: initialFilterState,
  dispatch: () => null
});

export const FilterProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(filterReducer, initialFilterState);

  return (
    <FilterContext.Provider value={{ state, dispatch }}>
      {children}
    </FilterContext.Provider>
  );
};

export const useFilterContext = () => useContext(FilterContext);
