import { FC, memo, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import gsap from 'gsap';
import { useGSAP } from '@gsap/react';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { Box, Grid, Image, Text } from '@mantine/core';
import { AnimatedText } from 'src/modules/landing-screens/components';
import { headerTitles } from 'src/modules/header/components/header-title';
import logoImg from 'src/assets/icons/logo-black.png';

interface HeaderProps {
  title?: string;
  paddingLeft?: number;
  navigationElem?: JSX.Element | null;
  withAnimation?: boolean;
}

gsap.registerPlugin(useGSAP);
gsap.registerPlugin(ScrollTrigger);

export const Header: FC<HeaderProps> = memo(
  ({ title, paddingLeft, navigationElem, withAnimation = true }) => {
    const container = useRef<HTMLDivElement | null>(null);
    const { pathname } = useLocation();

    const titleByRoute =
      headerTitles[pathname as keyof typeof headerTitles] || '';

    const tl = useRef<GSAPTimeline | null>(null);

    useGSAP(
      () => {
        if (!withAnimation) return;

        tl.current = gsap.timeline({
          delay: 0.5,
          defaults: {
            duration: 2,
          },
        });

        tl.current.fromTo(
          '#logo',
          { opacity: 0 },
          {
            opacity: 1,
          }
        );

        tl.current.fromTo(
          '#line',
          { width: 0 },
          {
            width: '100vw',
          },
          '-=1'
        );

        tl.current.fromTo(
          '#burger',
          { opacity: 0 },
          {
            opacity: 1,
          },
          '-=0.5'
        );
      },
      { scope: container }
    );

    return (
      <Box
        ref={container}
        pos="fixed"
        w="100%"
        bg="white"
        h={'8%'}
        top={0}
        left={0}
        pl={paddingLeft}
        style={{ zIndex: 50, transition: 'padding 0.1s linear' }}
      >
        <Grid
          px="5%"
          align="center"
          w="100%"
          h="100%"
          styles={{ inner: { height: '100%' } }}
        >
          <Grid.Col span={5}>
            {withAnimation ? (
              <AnimatedText
                toColor="#ccc"
                delay={1}
                textContent={title || titleByRoute}
                textSize={38}
              />
            ) : (
              <Text c="#333" lh={1.3} fw={500} fz={38}>
                {title || titleByRoute}
              </Text>
            )}
          </Grid.Col>

          <Grid.Col id="logo" span={2}>
            <Image src={logoImg} alt="logo" w={80} mx="auto" />
          </Grid.Col>

          <Grid.Col span={5} ta="right">
            {navigationElem}
          </Grid.Col>
        </Grid>

        <Box
          id="line"
          className="mt-2"
          style={{ borderBottom: '1px solid black' }}
        />
      </Box>
    );
  }
);
